body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg: #214151;
  --fc: #EFF7E1;
  --hc: #F8DC81;
  --ac: #A2D0C1;
}

@font-face {
  font-family: 'Overpass Mono';
  src: url('/fonts/OverpassMono-Regular.ttf');
}

a {
  color: #A2D0C1;
  color: var(--ac);
}

h1, h2, h3 {
  color: #F8DC81;
  color: var(--hc);
}

nav {
  display: flex;
  font-size: calc(6px + 2vmin);
  grid-gap: 20px;
  gap: 20px;
  justify-content: right;
  padding-top: 5%;
  padding-bottom: 5%;
}

body {
  background-color: #214151;
  background-color: var(--bg);
  color: #EFF7E1;
  color: var(--fc);
  font-family: "Overpass Mono";
  font-size: calc(3px + 1.5vmin);
}

.page {
  padding-left: 18%;
  padding-right: 18%;
}
