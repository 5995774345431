:root {
  --bg: #214151;
  --fc: #EFF7E1;
  --hc: #F8DC81;
  --ac: #A2D0C1;
}

@font-face {
  font-family: 'Overpass Mono';
  src: url('/fonts/OverpassMono-Regular.ttf');
}

a {
  color: var(--ac);
}

h1, h2, h3 {
  color: var(--hc);
}

nav {
  display: flex;
  font-size: calc(6px + 2vmin);
  gap: 20px;
  justify-content: right;
  padding-top: 5%;
  padding-bottom: 5%;
}

body {
  background-color: var(--bg);
  color: var(--fc);
  font-family: "Overpass Mono";
  font-size: calc(3px + 1.5vmin);
}

.page {
  padding-left: 18%;
  padding-right: 18%;
}